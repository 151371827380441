import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Skill Practice: Rope Climbs (legs, legless, l hold, etc)`}</p>
    <p>{`then,`}</p>
    <p>{`25-Cal Assault Bike`}</p>
    <p>{`25-Med Ball Situps (20/14)`}</p>
    <p>{`5-Rope Climbs (15′)`}</p>
    <p>{`20-Cal Assault Bike`}</p>
    <p>{`20-Med Ball Situps`}</p>
    <p>{`4-Rope Climbs`}</p>
    <p>{`15-Cal Assault Bike`}</p>
    <p>{`15-Med Ball Situps`}</p>
    <p>{`3-Rope Climbs`}</p>
    <p>{`10-Cal Assault Bike`}</p>
    <p>{`10-Med Ball Situps`}</p>
    <p>{`2-Rope Climbs`}</p>
    <p>{`5-Cal Assault Bike`}</p>
    <p>{`5-Med Ball Situps`}</p>
    <p>{`1-Rope Climb`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      